import React from "react"

import Layout from "@components/layout"
import Page from "@components/page"
import SEO from "@components/seo"

const PrivacyPage = () => (
  <Layout bodyClassName='privacy'>
      <SEO 
        title="Privacy Policy"
        description="Here is the privacy policy for when you're using Harper." 
      />

    <Page>
      <h1>Privacy Policy</h1>

      <p className='metadata'>Last revised June 1, 2018</p>

      <p>Loyalty is something dogs are known for. It&rsquo;s important for us at Harper too. One of the most important ways we can be loyal to you is by earning and keeping your trust. That means respecting your privacy and being clear about how we rely on your data to build the best dog training experience for you.</p>

      <p>{`This Privacy Policy ("Privacy Policy") details the personal data that Harper Pack, LLC ("Harper", "we", "us", or "our") receive about you, how we process it, and your rights and obligations in relation to your personal data.`}</p>

      <p>{`By using or accessing Harper's website and related mobile applications and services (collectively, the “Services”), you're agreeing to the terms of this Privacy Policy. We may update our Privacy Policy to reflect changes to our information practices. If we do this and the changes are material, we'll post a notice that we've made changes to the Privacy Policy on the Website for at least 7 days before the changes are made, and we will indicate the date these terms were last revised at the bottom of the Privacy Policy. Any revisions to this Privacy Policy will become effective at the end of that 7 day period.`}</p>

      <h2>Information we collect</h2>
      <p>When you use Harper to train your dog, you provide us with information you submit via the Service, and we collect information regarding your use of the Service as you interact with it.</p>

      <h3>Information you provide</h3>
      <p>Through the registration process, you&rsquo;ll provide us with your name, phone number, email address, and information about your dog(s).</p>

      <h3>Activity data</h3>
      <p>When you use the Service, we generate data about your use of the Service including your engagement in the dog training games. We also collect technical data about how you interact with our Services.</p>

      <h2>Information obtained by third parties</h2>
      <p>To support and enhance the Service, we work with third party marketing analytics service providers such as Google Analytics or Amplitude. Google Analytics and Amplitude help us understand how folks such as yourself like to use our service. Providers like this collect and use non-personally identifiable information, like your your mobile device ID, about your use of the websites and mobile application.</p>

      <p>Google Analytics uses first-party cookies to track user interactions as in our case, where they are used to collect information about how users use our site. This information is used to compile reports and to help us improve our Website. The reports disclose website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our site – for more information on opting out of being tracked by Google Analytics across all websites you use, <a href={"https://tools.google.com/dlpage/gaoptout"}>visit this Google page</a>.</p>

      <h2>Use of information obtained by Harper</h2>
      <p>Harper may occasionally send you service related and product change announcements through the general operation of the Service. We&rsquo;ll send you reminder notifications to support our training methodology by reinforcing a training cadence to help you and your pup develop a habit. We process your data to help us understand and improve the Service and to keep the Service safe and secure. You may opt out of any non-essential service emails at any time.</p>

      <h2>Sharing your personal data with third parties</h2>
      <p>Harper shares your personal data only when it is necessary to offer the Service, legally required, or permitted by you.We will provide personal data to hosting providers such as Amazon Web Services, search engine providers such as Google, and analytics providers such as Amplitude and Crashlytics. These data processors help us bring you the Service.</p>

      <h2>Data subject rights and data retention</h2>
      <p>You can manage your account settings from within the application to update, amend, and correct your information.</p>

      <h2>California privacy rights</h2>
      <p>California Civil Code Section 1798.83 entitles residents of the State of California to request from a business, with whom that resident has an established business relationship, certain information regarding the types of personal data the business shares with third parties for direct marketing purposes by such third party and the identities of the third parties with whom the business has shared such information during the immediately preceding calendar year. If you would like to request a copy of this information disclosure from Harper, please contact us at <a href='mailto:nick@harper.dog'>nick@harper.dog</a>.</p>

      <h2>Children</h2>
      <p>Our Service is not directed to children under 13. We will never knowingly collect any personally identifiable information about children under the age of 13. If Harper obtains actual knowledge that it has collected personal information about a child under the age of 13, that information will be immediately deleted and will not be shared or disclosed to third parties.</p>

      <h2>Information security</h2>
      <p>Harper has implemented administrative and technical safeguards it believes are appropriate to protect the confidentiality, integrity and availability of your personal data. No data transmission over the Internet however, can be guaranteed to be absolutely secure, and Harper cannot ensure or warrant the security of any information you transmit to Harper. Transmitting personal information is done at your own risk.</p>

      <h2>Contact us</h2>
      <p>For all data privacy inquiries and any questions or concerns you have about this Privacy Policy, please contact us at <a href='mailto:nick@harper.dog'>nick@harper.dog</a></p>
    </Page>
  </Layout>
);

export default PrivacyPage
